import React from 'react';
import CenteredIcon from './shared/CenteredIcon';
import { Container, Page, GridRow } from './shared/styled/Assembly';
import { pictoWomp } from '../assets';

const AppNotSupported = () => (
  <Page>
    <GridRow>
      <Container>
        <CenteredIcon>
          <img src={pictoWomp} alt="" />
        </CenteredIcon>
        <h1 className="text24--emphasis text-center">
          Sorry, we don&#39;t support this browser.
        </h1>
        <p className="text-center">
          Please use the latest{' '}
          <a href={`microsoft-edge:${window.location.href}`}>Microsoft Edge</a>{' '}
          browser or another modern browser.
        </p>
      </Container>
    </GridRow>
  </Page>
);

export default AppNotSupported;
