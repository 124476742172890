import React from 'react';
import Responsive, { MediaQueryProps } from 'react-responsive';

export const Desktop = (props: MediaQueryProps) => (
  <Responsive {...props} minWidth={992} />
);
export const Tablet = (props: MediaQueryProps) => (
  <Responsive {...props} minWidth={768} />
);
export const Mobile = (props: MediaQueryProps) => (
  <Responsive {...props} maxWidth={767} />
);
export const Tiny = (props: MediaQueryProps) => (
  <Responsive {...props} minWidth={481} />
);

export default {
  mobile: 'only screen and (max-width: 767px)',
  tablet: 'only screen and (min-width: 768px)',
};
