import { theme } from '@go-shippit/shippit-ui-library';

// Overriding colours from Shippit ui library for re-branding purpose
export default {
  primary: theme.colors.primary[500],
  secondaryText: '#636363',
  grey: '#A5A5A5',
  lightGrey: '#C5C5C5',
  lightGrey20: '#F4F4F4',
  lightGrey40: '#E0E0E0',
  neutral: '#F5A623',
  neutral20: '#FFECCC',
  critical: '#FF4338',
  critical20: '#FCE8E8',
  success: '#72D4A4',
  success40: '#C7EEDB',
  white: theme.colors.neutral[100],
  whiteSmoke: '#F5F5F5',
  black: '#2C2C2C',
};

export const focus = (placement = '') => `
  outline: none;
  box-shadow: ${placement} 0 0 0 3px rgba(8, 132, 252, 0.3);
`;
