import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import '@go-shippit/shippit-ui-library/dist/esm/index.css';

import './utils/datadog';

import isInternetExplorer from './logic/IsInternetExplorer';
import AppNotSupported from './components/AppNotSupported';

import 'react-tippy/dist/tippy.css';
import './styles/index.css';
import './assets/index.scss';

const App = React.lazy(() => import('./App'));
const root = createRoot(document.getElementById('root')! as Element);
root.render(
  isInternetExplorer(window.navigator.userAgent) ? (
    <AppNotSupported />
  ) : (
    <Suspense fallback={<div />}>
      <App />
    </Suspense>
  )
);
