import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Colours from './Colours';
import Media from './Media';

export const Container = styled.div`
  padding: 1.25rem 0 0 0;
`;

export const Break = styled.hr`
  background-color: ${Colours.lightGrey20};
  border: none;
  height: 2px;
  padding: 0;
  margin: 0;
`;

export const Actions = styled.div`
  margin: 20px auto 0 auto;
  text-align: center;

  a,
  button {
    width: 100%;
  }

  @media ${Media.tablet} {
    max-width: 320px;
  }
`;

export const PageContent = styled.div`
  opacity: 0;
  animation-name: appear;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.08, 0.99);
  animation-iteration-count: infinte;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
`;

export const Page = styled.div`
  margin: auto;
  padding: 0 0 1.5rem 0;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .${PageContent} {
    opacity: 0;
    animation-name: appear;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.65, 0.05, 0.08, 0.99);
    animation-iteration-count: infinte;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
  }
`;

interface IGridRow {
  children?: ReactNode;
}

export const GridRow = ({ children }: IGridRow) => (
  <Grid fluid={false}>
    <Row center="sm">
      <Col xs={12}>{children}</Col>
    </Row>
  </Grid>
);
GridRow.defaultProps = {
  children: undefined,
};
