import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

const CenteredIcon = ({ children, className }: Props) => (
  <Container className={className}>{children}</Container>
);
CenteredIcon.defaultProps = {
  className: '',
};

export default CenteredIcon;
