import { datadogRum } from '@datadog/browser-rum';

if (
  process.env.REACT_APP_ENV !== 'local' &&
  process.env.REACT_APP_ENV !== 'test'
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    env: process.env.REACT_APP_ENV,
    service: 'returns-portal',
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [process.env.REACT_APP_API_ENDPOINT],
  });
}

export const addCustomDatadogError = (message, error) => {
  const info = new Error(message);
  datadogRum.addError(info, { error });
};
